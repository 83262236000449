








import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/admin/components/PageHeader.vue'
import __ from '@/shared/helpers/__'
import { CompanyRoutes } from '@/shared/router/company'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/form/form-base'
import Tool from '@/shared/modules/tool/models/tool.model'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import Field, { FieldSizes } from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import SearchableField from '@/shared/classes/form/fields/Select/searchable-field'
import ToolTypeFilesDialog from '@/company/views/tools/ToolTypeFilesDialog.vue'
import _ from 'lodash'
import { getBase64 } from '@/shared/helpers/get-base64.helper'
import { FilterOperators } from '@/shared/classes/components/data-table/data-table-filter'
import { RoleLevel } from '@/shared/modules/role/role.model'

@Component({
  components: { PageHeader, Form },
  methods: { __ }
})
export default class ToolsCreate extends Vue {
  form: FormBase = null!

  created() {
    this.form = new FormBase()
      .setEndpoint(`company/${ this.company.slug }/tools`)
      .setModel(Tool)
      .setInjectValues({
        company_uuid: this.company.uuid
      })
      .setFields([
        new Field()
          .setType(FieldTypes.number)
          .setKey('internal_no')
          .setTitle(__('company.views.tools.form.details.internal_no'))
          .isRequired(),
        new Field()
          .setKey('name')
          .setTitle(__('company.views.tools.form.details.name'))
          .isRequired(),
        new SearchableField()
          .setKey('category_uuid')
          .setTitle(__('company.views.tools.form.details.category'))
          .loadItems({
            endpoint: `/company/${ this.company.slug }/tool-categories`,
            value: 'uuid',
            title: 'name',
            perPage: 20,
            filters: [
              {
                type: FilterOperators.nulled,
                name: 'deactivated_at',
                value: true
              }
            ]
          })
          .isRequired(),
        new SearchableField()
          .setKey('location_uuid')
          .setTitle(__('company.views.tools.form.details.location'))
          .loadItems({
            endpoint: `/company/${ this.company.slug }/tool-locations`,
            value: 'uuid',
            title: 'name',
            perPage: 20,
            filters: [
              {
                type: FilterOperators.nulled,
                name: 'deactivated_at',
                value: true
              }
            ]
          })
          .isRequired(),
        new SearchableField()
            .setKey('retrieved_by_user_uuid')
            .setTitle(__('company.views.tools.form.details.retrieved_by'))
            .loadItems({
              endpoint: `/company/${ this.company.slug }/employees`,
              value: 'uuid',
              title: 'full_name',
              perPage: 20
            })
            .setDisabled(true)
            .setSize(FieldSizes.half),
        new Field()
          .setType(FieldTypes.datePicker)
          .setKey('retrieved_date')
          .setEntryKey('retrievedDate')
          .setTitle(__('company.views.tools.form.details.retrieved_date'))
          .setDisabled(true)
          .setSize(FieldSizes.half),
        new Field()
          .setType(FieldTypes.datePicker)
          .setKey('purchased_date')
          .setEntryKey('purchasedDate')
          .setTitle(__('company.views.tools.form.details.purchased_date'))
          .setSize(FieldSizes.half),
        new Field()
          .setKey('purchased_place')
          .setTitle(__('company.views.tools.form.details.purchased_place'))
          .setSize(FieldSizes.half),
        new Field()
          .setKey('manufacturer')
          .setTitle(__('company.views.tools.form.details.manufacturer')),
        new Field()
          .setType(FieldTypes.number)
          .setKey('price')
          .setTitle(__('company.views.tools.form.details.price'))
          .setSize(FieldSizes.fourTwelfth),
        new Field()
          .setType(FieldTypes.number)
          .setKey('rental_internal_price')
          .setTitle(__('company.views.tools.form.details.rental_internal_price'))
          .setSize(FieldSizes.fourTwelfth),
        new Field()
          .setType(FieldTypes.number)
          .setKey('rental_external_price')
          .setTitle(__('company.views.tools.form.details.rental_external_price'))
          .setSize(FieldSizes.fourTwelfth),
        new Field()
          .setType(FieldTypes.checkbox)
          .setKey('fixed_location')
          .setTitle(__('company.views.tools.form.details.fixed_location'))
          .setSize(FieldSizes.half)
          .isRequired(),
        new Field()
          .setType(FieldTypes.datePicker)
          .setKey('expected_finished')
          .setEntryKey('expectedFinished')
          .setTitle(__('company.views.tools.form.details.expected_finished'))
          .setSize(FieldSizes.half),
        new Field()
          .setKey('link')
          .setTitle(__('company.views.tools.form.details.link')),
        new Field()
          .setType(FieldTypes.textArea)
          .setKey('comment')
          .setTitle(__('company.views.tools.form.details.comment')),
        new Field()
          .setType(FieldTypes.custom)
          .setKey('photos')
          .setComponent(ToolTypeFilesDialog)
          .setValue([])
      ])
      .setSubmit({
        text: __('company.views.tools.create.form.submit')
      })
      .setBeforeSubmit(this.submit)
      .setOnSuccess(this.onSuccess)
  }

  
async submit() {
  if (_.get(this.form.data, 'photos')) {
    await Promise.all(this.form.data.photos.map(async (toolPhoto: any, toolPhotoIndex: number) => {
      const base64 = await getBase64(toolPhoto.file)
      _.set(this.form.data, `photos.${ toolPhotoIndex }.file_name`, toolPhoto.file.name)
      _.set(this.form.data, `photos.${ toolPhotoIndex }.file`, base64)
    }))
  }
}

  goBack(): void {
    this.$router.push({ name: CompanyRoutes.toolsIndex })
  }

  private onSuccess(): void {
    this.$store.dispatch(GlobalActions.showSnackBar, {
      type: SnackBarTypes.success,
      message: __('company.views.tools.create.form.on-success'),
    })
    this.goBack()
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
